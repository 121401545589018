<template>
  <v-select 
    dense
    @input="onInput"
    item-text="name"
    item-value="name"
    :items="byCountry"
    :label="label"
    outlined
    :clearable="clearable"
  ></v-select>
</template>

<script>
import regionsData from "country-region-data";
export default {
  data() {
    return {
      all: regionsData,
    };
  },
  computed: {
    byCountry() {
      const self = this;
      let data = self.all.filter(function (item) {
        return item.countryShortCode == self.CountryCode;
      });
      if (!data.length) return [];
      return data[0].regions;
    },
  },
  methods: {
    onInput(value) {
      value = value ? value.toLowerCase() : value;
      this.$emit("input", value);
    },
  },
  props: {
    clearable: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    /* Puerto Rico default */
    CountryCode: {
      type: String,
      default: "PR",
    },
  },
};
</script>

<style>
</style>